<template>
  <div>
    <el-select
      v-model="selectedAddress"
      style="width: calc(100% - 20px)"
      ref="field"
      filterable
      clearable
      remote
      value-key="key"
      :loading="loading"
      :remote-method="search"
      :placeholder="placeholder"
      @focus="setDefaultAddress"
      @clear="clearSelectedAddress"
    >
      <i slot="prefix" class="el-icon-search" style="margin-left: 5px; font-weight: bold"></i>
      <el-option
        v-for="item in searchOptions"
        :key="item.key"
        :label="getAddressLabel(item)"
        :value="item"
        class="el-option-content">
        <div class="el-option-text" @click="changeSelected(item);">
          {{ getAddressLabel(item) }}
        </div>
        <div class="el-option-buttons">
          <el-button type="text" size="small" class="el-option-button" @click="changeSelected(item, true)">
            <i class="el-icon-setting el-icon--left"></i>
            {{ $locale.address_service.button.specify }}
          </el-button>
        </div>
      </el-option>
    </el-select>
    <i
      :class="{'el-icon-arrow-down': !showAddressObjects, 'el-icon-arrow-up': showAddressObjects}"
      style="margin-left: 5px; font-weight: bold; cursor:pointer;"
      @click="showAddressObjects = !showAddressObjects"></i>
    <address-objects
      :is-active.sync="showAddressObjects"
      :dropdown-width="dropdownWidth"
      :level="level"
      :type="type"
      :required-level="requiredLevel"
      :selected-address="selectedAddress"
      @set-address="setSelectedAddress"
      @additional-parameters-loaded="addAddressParameters"
      style="margin-top: 5px"
    ></address-objects>
  </div>
</template>

<script>

  import AddressObjects from './AddressObjects'

  export default {
    name: 'gar_address_field',
    components: { AddressObjects },
    props: {
      level: {
        type: Number
      },
      placeholder: {
        type: String
      },
      searchLimit: {
        type: Number,
        default: 50
      },
      value: {
        type: Object
      },
      requiredLevel: {
        type: String
      },
      defaultAddress: {
        type: String
      },
      type: {
        type: String,
        default: 'administrative'
      }
    },
    data () {
      return {
        selectedAddress: this.value || {},
        searchOptions: [],
        showAddressObjects: false,
        dropdownWidth: 100,
        loading: false
      }
    },
    computed: {
      resultedValue () {
        if (Object.keys(this.selectedAddress || {}).length === 0) {
          return null
        }
        return {
          id: this.selectedAddress.address_id || null,
          level: this.selectedAddress.level || this.level,
          region_code: this.selectedAddress.region_code,
          objectid: this.selectedAddress.objectid,
          type: this.type,
          house: (this.level === 10 && (this.selectedAddress.house && this.selectedAddress.house.name)) ? this.selectedAddress.house.name : null,
          room: this.selectedAddress.flat || null,
          description: this.selectedAddress.description || null
        }
      }
    },

    watch: {
      value: {
        handler (newValue) {
          this.searchOptions = [newValue]
          if (newValue?.address_id !== this.selectedAddress?.address_id) {
            this.selectedAddress = newValue
            this.selectedAddress.flat = newValue.room
          }
        },
        deep: true
      },
      selectedAddress: {
        handler (newValue) {
          this.$emit('input', this.resultedValue)
        },
        deep: true
      },
      showAddressObjects (value) {
        if (value) {
          this.updateDropdownWidth()
        }
      }
    },

    mounted () {
      this.updateDropdownWidth()
    },

    methods: {
      updateDropdownWidth () {
        this.dropdownWidth = this.$el.offsetWidth > 500 ? 500 : this.$el.offsetWidth - 20
      },
      setDefaultAddress () {
        if (this.defaultAddress) {
          const inputEl = this.$refs.field.$el.children[0].children[0]
          inputEl.value = this.defaultAddress
          this.search(this.defaultAddress)
        }
      },
      addAddressParameters (parameters) {
        /*Object.entries(parameters).forEach((item) => {
          console.log(this.selectedAddress[item[0]] === item[1])
        })
        */
        //Object.assign(this.selectedAddress, parameters)
      },
      // проставить адрес из заполненной формы
      async setSelectedAddress (address) {
        if (Object.keys(address || {}).length === 0) {
          this.clearSelectedAddress()
          return
        }
        let option = await this.loadAddress(address) || {}
        if (Object.keys(option).length > 0) {
          this.selectedAddress = option
        } else {
          this.selectedAddress = address
        }
      },
      // загружаем адрес в селект
      async loadAddress (address) {
        if (Object.keys(address.data || {}).length === 0 || !address.type) {
          return
        }
        let url = null
        if (address.type === 'house') {
          if (address.data.id) {
            url = `${this.$config.api}/garaddressservice/addresses/houses/${address.data.id}/${this.type}`
          } else {
            //ввели руками
            url = `${this.$config.api}/garaddressservice/addresses/address_objects/${address.data.previous}/${this.type}`
          }
        } else {
          url = `${this.$config.api}/garaddressservice/addresses/address_objects/${address.data.id}/${this.type}`
        }
        if (!url) {
          return false
        }
        const fiasData = await this.$http.get(url)
        const fiasAddress = fiasData.data
        if (!fiasAddress) {
          console.warn('address not found')
          return false
        }
        let option = fiasAddress
        /* Other fields */
        this.$set(option, 'flat', address.flat)
        // По умолчанию null, на сервере проверка дублей по NULL
        this.$set(option, 'description', address.description || null)
        if (['house'].includes(address.type) && address.data.id) {
          this.$set(option, 'house', { name: address.data.name })
        }

        // Для привязки существующего адреса по id (не address_id). Новый адрес если заполнен description.
        // - Может быть заполнен из-за выбора flat (см. address_id_flat в save в AddressObject.vue)
        if (address.id) {
          // ID на входе в функцию loadAddress есть, привязать существующий адрес по ID
          this.$set(option, 'id', address.id)
        } else if (option.address_id && !address.flat && !address.description) {
          // Привязать к существующему адресу только при не заполненом flat и description
          this.$set(option, 'id', option.address_id)
        }
        // address_id от сервера привязанный к house, приравниваем к id, чтобы не сбивало с толку при отладке
        this.$set(option, 'address_id', option.id)

        this.$set(option, 'level', option.flat ? 11 : address.level)

        /*
        // Ключ адреса для проверки дублей с учётом flat, description
        //${option.municipal_area_guid}
        const key =
          `${option.subject_guid}_1_${option.city_guid}_${option.locality_guid}_${option.planning_structure_guid}_${option.street_guid}` +
          `_${option.addrobj_aoid}_${option.house_houseid}_${option.address_level_id}_${address.flat}_${address.description}`
        */
        this.$set(option, 'key', `${option.objectguid}_${option.house}_${(option.flat && option.flat.name) ? option.flat.name : option.flat}_${option.description}`)
        //option = Object.assign(option, address.addressObjectsValue)

        if (!this.searchOptions.find(item => item.key === option.key)) {
          // Адрес с ключем не найден, добавить адрес в список поиска
          this.searchOptions.push(option)
        }

        return option
      },
      async search (query) {
        if (!query) {
          this.searchOptions = []
          return false
        }
        this.loading = true
        query = encodeURIComponent(query)
        const fiasData = await this.$http.get(`${this.$config.api}/garaddressservice/addresses/find/${this.level}/${this.type}/${query}?limit=${this.searchLimit}`)
        this.searchOptions = fiasData.data.map(fiasAddress => {
          const key = fiasAddress.name + '_' + fiasAddress.objectguid//Object.values(JSON.parse(fiasAddress.object_ids.replace('\"', '"'))).join('_')
          return Object.assign(fiasAddress, { key })
        })

        this.loading = false
      },
      async getAdditionalAddressProperties(objectIdsJSON) {
        let result = {}
        const objectIds = JSON.parse(objectIdsJSON.replace('\"', '"'))
        const keys = Object.keys(objectIds)
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]
          const value = objectIds[key]
          if (key === 'houses') {
            const addressItemData = await this.$http.get(`${this.$config.api}/garaddressservice/houses/${value}`)
            result[key] = addressItemData.data
          } else if (['flat', 'description'].includes(key)) {
            result[key] = value
          } else {
            const addressItemData = await this.$http.get(`${this.$config.api}/garaddressservice/address_objects/${value}`)
            result[key] = addressItemData.data
          }
        }
        return result
      },
      // выбор адреса из выпадающего списка
      async changeSelected (address, isShowAddressObjects = false) {
        if (!address) {
          return
        }
        //const realAddress = await this.$http.get(`${this.$config.api}/garaddressservice/addresses/${address.objectid}`)
        // address_id от сервера привязанный к house, приравниваем к id
        this.$set(address, 'id', address.address_id)
        /* Other fields */
        // По умолчанию null, на сервере проверка дублей по NULL
        this.$set(address, 'description', address.description || null)
        //this.$set(this, 'selectedAddress', address)
        this.selectedAddress = address

        if (isShowAddressObjects) {
          // Показать модальное окно адреса
          this.showAddressObjects = true
        }
      },
      clearSelectedAddress () {
        //this.selectedAddress = null
        this.$set(this, 'selectedAddress', null)
      },
      getAddressLabel (address) {
        let addressLabel = address.name // Может содержать flat

        if (this.level >= 10 && address.house && !addressLabel.includes(', д.')) {
          addressLabel += ', ' + address.house.name
        }

        if (address.flat && !addressLabel.includes(', кв.') /* Проверить содержание flat */) {
          addressLabel += ', кв. ' + address.flat
        }

        if (address.description) {
          addressLabel += ', ' + address.description
        }

        return addressLabel
      }
    }
  }
</script>

<style scoped>

</style>
